<template>
  <section>
    <v-card-title>
      Notifications
      <v-spacer></v-spacer>
      <!-- <v-select
        class="filter-input mr-2"
        single-line
        v-if="isAdmin || isClientUser || isClientAdmin || isDriver"
        hide-details
        :items="
          [
            {
              text: 'All Types',
              value: '',
            },
          ].concat(
            notificationTypes.map((item) => {
              return {
                text: item.text,
                value: item.value,
              };
            })
          )
        "
        v-model="notificationTypeSelected"
        label="All Types"
      ></v-select> -->
      <v-checkbox
        class="filter-checkbox mr-4"
        value
        v-model="onlyMyOrders"
        v-if="isClientAdmin || isClientUser"
        label="Only My Orders"
      ></v-checkbox>
      <v-select
        class="filter-input mr-2"
        single-line
        v-if="isAdmin || isClientUser || isClientAdmin"
        hide-details
        :items="
          !users
            ? [
                {
                  text: 'All Drivers',
                  value: null,
                },
                {
                  text: 'Not Assigned',
                  value: -1,
                },
              ]
            : [
                {
                  text: 'All Drivers',
                  value: null,
                },
                {
                  text: 'Not Assigned',
                  value: -1,
                },
              ].concat(
                users.map((item) => {
                  return {
                    text: item.firstName + ' ' + item.lastName,
                    value: parseInt(item.id),
                  };
                })
              )
        "
        v-model="driver"
        label="All Drivers"
      ></v-select>
      <v-select
        class="filter-input mr-2"
        single-line
        hide-details
        v-if="isAdmin"
        :items="
          !customers
            ? [
                {
                  text: 'All Clients',
                  value: '',
                },
              ]
            : [
                {
                  text: 'All Clients',
                  value: '',
                },
              ].concat(
                customers.map((item) => {
                  return {
                    text: item.name,
                    value: parseInt(item.id),
                  };
                })
              )
        "
        v-model="customer"
        label="All Clients"
      ></v-select>
      <v-select
        class="filter-input mr-2"
        single-line
        hide-details
        v-if="isClientUser || isClientAdmin"
        :items="
          [
            {
              text: 'All Passengers',
              value: null,
            },
          ].concat(
            patients
              .filter((item) => item !== null)
              .map((item) => {
                return {
                  text: item.firstName + ' ' + item.lastName,
                  value: parseInt(item.id),
                };
              })
          )
        "
        v-model="patient"
        label="All Passengers"
      ></v-select>
      <div class="d-flex justify-center" style="height: 47px">
        <v-select
          v-if="timeframe !== -100"
          class="filter-input"
          single-line
          hide-details
          :items="
            !timeframes
              ? [
                  {
                    text: 'This Week',
                    value: 1,
                  },
                ]
              : [
                  {
                    text: 'This Week',
                    value: 1,
                  },
                ].concat(timeframes)
          "
          v-model="timeframe"
          label="This Week"
        ></v-select>
        <div style="width: 160px" v-if="timeframe === -100">
          <v-dialog
            ref="dialogStart"
            v-model="filterDateStartModal"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterDateStart"
                label="Date From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                style="width: 150px"
                class="ml-2"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterDateStart"
              @click:date="filterDateStartModal = false"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filterDateStartModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogStart.save(filterDateStart)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div style="width: 160px" v-if="timeframe === -100">
          <v-dialog ref="dialogEnd" v-model="filterDateEndModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterDateEnd"
                label="Date To"
                prepend-icon="mdi-calendar"
                readonly
                style="width: 150px"
                class="ml-2 mr-2"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterDateEnd"
              @click:date="filterDateEndModal = false"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filterDateEndModal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogEnd.save(filterDateEnd)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <v-btn
          v-if="timeframe == -100"
          icon
          @click="timeframe = ''"
          style="mr-2"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="notifications"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
      :items-per-page="30"
    >
      <template v-slot:item.created_at="{ item }">
        <span
          v-if="item.created_at"
          v-luxon:med="{
            value: item.created_at,
          }"
        />
      </template>
      <template v-slot:item.made_by="{ item }">
        {{
          item.made_by
            ? item.made_by.firstName + " " + item.made_by.lastName
            : "-"
        }}
      </template>
      <template v-slot:item.title="{ item }">
        <div class="text-medium">
          {{ item.title }} {{ item.order ? "#" + item.order.id : "" }}
        </div>
        <div v-if="item.subOrder" class="text-medium text-gray">
          <span
            v-if="item.subOrder"
            v-luxon:date_med="{
              value: item.subOrder.date + 'T12:00:00',
            }"
          />
        </div>
      </template>
      <template v-slot:item.message="{ item }">
        <div style="max-width: 500px">
          {{ item.message }}
        </div>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import Vue from "vue";
import { DateTime } from "luxon";
import axios from "axios";
import NotificationsComponent from "@/components/data/NotificationsComponent";

import { mapGetters } from "vuex";
export default {
  name: "Notifications",
  components: {
    NotificationsComponent,
  },
  apollo: {
    Notifications: {
      prefetch: true,
      query: require("@/graphql/Notifications.gql"),
      pollInterval: 15000,
      variables() {
        if (new Date(this.timeframeStart) > new Date(this.timeframeEnd)) {
          const temp = this.timeframeStart;
          this.timeframeStart = this.timeframeEnd;
          this.timeframeEnd = temp;
        }
        return {
          type: this.notificationTypeSelected,
          timeframeStart: this.timeframeStart,
          timeframeEnd: this.timeframeEnd,
          customer: this.customer,
          driver: this.driver,
          patient: this.patient,
        };
      },
      update: (data) => data
    },
    Users: {
      prefetch: true,
      fetchPolicy: "cache-and-network",
      query: require("@/graphql/UsersForSelect.gql"),
      update: (data) => data,
      result(data) {
        if (!data.data) return;
        console.log(data.data.users)
        this.users = data.data.users;
      },
    },
    Patients: {
      prefetch: true,
      fetchPolicy: "cache-and-network",
      query: require("@/graphql/Patients.gql"),
      update: (data) => data,
      result(data) {
        if (!data.data) return;
        this.patients = data.data.patients;
      },
    },
  },

  computed: {
    ...mapGetters([
      "user",
      "strapi",
      "isDriver",
      "isAdmin",
      "isClientUser",
      "isClientAdmin",
    ]),
    notifications() {
      if (this.Notifications && this.Notifications.notificationsSearch) {
        return this.Notifications.notificationsSearch;
      }
      return [];
    },
    customers() {
      if (this.Notifications && this.Notifications.customers)
        return this.Notifications.customers;
      return [];
    },
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "created_at",
          filterable: false,
        },
        {
          text: "Person",
          sortable: false,
          value: "made_by",
          filterable: false,
        },
        { text: "Title", value: "title", filterable: false },
        { text: "Message", value: "message", filter: this.searchFilter },
      ];
    },
  },
  watch: {
    notificationTypeSelected(val) {
      this.$apollo.queries.Notifications.refresh();
    },
    filterDateStart() {
      this.updateFilterDates();
    },
    filterDateEnd() {
      this.updateFilterDates();
    },
    timeframe(val) {
      var today = DateTime.local();
      today = DateTime.local(today.year, today.month, today.day);
      this.timeframeStart = this.filterDateStart;
      this.timeframeEnd = this.filterDateEnd = null;
      // console.log("timeframe", this.timeframe);
      switch (this.timeframe) {
        case -100: //Custom Range
          break;
        case -1: //Last Week
          // var endDate = today.plus({ days: 6 - today.weekday + 7 });
          var iterator = today.plus({
            days: (today.weekday == 7 ? 0 : -1 - today.weekday) - 7,
          });
          var endDate = today.plus({
            days: (today.weekday == 7 ? 6 : 6 - today.weekday) - 7,
          });
          this.timeframeStart = iterator.toISODate();
          this.timeframeEnd = endDate.toISODate();
          break;
        case 0: //Today
          // console.log(today);
          var todayISO = today.toISODate();
          // console.log("todayISO", todayISO);

          this.timeframeEnd = undefined;
          this.timeframeStart = todayISO;
          break;
        case 1: //This Week
          var iterator = today.plus({
            days: today.weekday == 7 ? 0 : -1 - today.weekday,
          });
          var endDate = today.plus({
            days: today.weekday == 7 ? 6 : 6 - today.weekday,
          });
          this.timeframeStart = iterator.toISODate();
          this.timeframeEnd = endDate.toISODate();
          break;
        case 2: //This Month
          var iterator = today.startOf("month");
          var endDate = today.endOf("month");
          this.timeframeStart = iterator.toISODate();
          this.timeframeEnd = endDate.toISODate();
          break;
        default:
          this.timeframeStart = "";
          this.timeframeEnd = "";
          break;
      }
      this.$apollo.queries.Notifications.refresh();
    },
    customer(val) {
      this.$apollo.queries.Notifications.refresh();
    },
    driver(val) {
      this.$apollo.queries.Notifications.refresh();
    },
    patient(val) {
      this.$apollo.queries.Notifications.refresh();
    }
  },
  data() {
    return {
      onlyMyOrders: true,
      patient: null,
      //
      timeframe: null,
      timeframeEnd: null,
      timeframeStart: null,
      //
      users: [],
      patients: [],
      //
      notificationTypeSelected: "",
      customer: null,
      driver: null,
      //
      filterDateStart: null,
      filterDateStartModal: false,
      filterDateEnd: null,
      filterDateEndModal: false,
      //
      notificationTypes: [
        {
          text: "All Orders",
          value: undefined,
        },
        {
          text: "Regular",
          value: "regular",
        },
        {
          text: "Critical",
          value: "critical",
        },
      ],
      timeframes: [
        {
          text: "Custom Range",
          value: -100,
        },
        {
          text: "Last Week",
          value: -1,
        },
        {
          text: "Today",
          value: 0,
        },
        {
          text: "This Month",
          value: 2,
        },
      ],
    };
  },
  methods: {
    searchFilter(_value, _search, item) {
      if (this.onlyMyOrders && !this.isAdmin) {
        if (item.made_by && item.made_by.id != this.user.id) {
          return false;
        }
      }
      return true;
    },
    updateFilterDates() {
      if (this.filterDateStart && this.filterDateEnd) {
        if (new Date(this.filterDateStart) > new Date(this.filterDateEnd)) {
          let temp = this.filterDateStart;
          this.filterDateStart = this.filterDateEnd;
          this.filterDateEnd = temp;
        }
        this.timeframeStart = this.timeStringToDateTime(
          this.filterDateStart
        ).toISODate();
        this.timeframeEnd = this.timeStringToDateTime(
          this.filterDateEnd
        ).toISODate();
      }
      this.$apollo.queries.Notifications.refresh();
    },
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    },
  },
};
</script>
